import React from 'react';
import Page from 'components/pages/Page';
import { PageContent } from 'components/ui';

const ArticlePage = ({ title, description, children }) => (
  <Page title={title} description={description}>
    <PageContent>
      <article>{children}</article>
    </PageContent>
  </Page>
);

export default ArticlePage;
