import React from 'react';
import jsCodeExample from './jsCodeExample.txt';
import listItemSnippet from './listItem.txt';
import pic from './pic.png';
import ArticlePage from 'components/pages/ArticlePage';
import {
  Text,
  P,
  A,
  NoWrap,
  Code,
  CodeBlock,
  Figure,
  FigCaption,
  H1,
  H2,
  Frac,
  Sups,
  Subs,
  Salt,
  List,
  Li,
  TabularLining
} from 'components/ui';

const DemoArticle = () => (
  <ArticlePage title="Demo article" description="">
    <Text>
      <H1>Demo article</H1>
      <P>
        With the numero sign, the written long-form of the address “Number 22
        Acacia Avenue” is shortened to “№ 22 Acacia Avenue”, yet both forms are
        spoken long. In Bluebook style, “Title 16 of the United States Code
        Section 580p” becomes “16 U.S.C. § 580p”. The section sign (§) is
        frequently used along with the pilcrow (¶) or paragraph sign to
        reference a specific paragraph within a section of a document. While §
        is usually spoken as section, European countries may read it as
        paragraph. When duplicated, as §§, it is read as the plural “sections”
        (e.g. “§§ 13–21”), much as “pp.” (pages) is the plural of “p.”
      </P>
      <P>
        The masculine ordinal indicator <Code>U+00BA</Code> (º) is often
        confused with the degree sign <Code>U+00B0</Code> (°), which looks very
        similar in many fonts and is available on the Italian keyboard layout.
        The degree sign is a uniform circle and is never underlined, while the
        letter o may be oval or elliptical and have a varying line thickness.
        The letter o may also be underlined. In correct typography, the ordinal
        indicators ª and º should be distinguishable from other characters.
      </P>
      <P>
        The chemical formula for water is H<Subs>2</Subs>O; carbon dioxide is CO
        <Subs>2</Subs>. The maximum density of water in its liquid form (at 1
        atm) is 1,000 kg/m
        <Sups>3</Sups> (62.43 lb/cu ft); that occurs at 3.98 °C (39.16 °F). The
        density of ice is 917 kg/m<Sups>3</Sups> (57.25 lb/cu ft). The inch
        (abbreviation: in or ″) is a unit of length in the (British) imperial
        and United States customary systems of measurement. It is equal to ​
        <Frac>1/36</Frac> yard or ​<Frac>1/12</Frac> of a foot. Subdivisions of
        an inch are typically written using dyadic fractions with odd number
        numerators; for example, two and three eighths of an inch would be
        written as 2⅜″ and not as 2.375″ nor as 2<Frac>2/16</Frac>″.
      </P>
      <P>
        A4 measures 210 × 297 millimeters or 8.27 × 11.69 inches. In PostScript,
        its dimensions are rounded off to 595 × 842 points. Folded twice, an A4
        sheet fits in a C6 size envelope (114 × 162 mm). In North America the
        Letter paper size (216 × 279 millimeters or 8½″ × 11″) is used instead
        of A4.
      </P>
      <P>
        Trademarks not officially registered can instead be marked with the
        trademark symbol ™. The registered trademark symbol ® is commonly in
        superscript style <Salt>®</Salt> but is not legally required. Because
        the © symbol has long been unavailable on typewriters and ASCII-based
        computer systems, it has been common to approximate this symbol with the
        characters (c).
      </P>
      <P>
        Johann Wolfgang von Goethe (1749–1832) ist einer der bedeutendsten
        Dichter der Deutschen. Das Universalgenie war nicht nur Schriftsteller,
        sondern auch Rechtsanwalt, Theaterleiter, Minister, Kunsttheoretiker und
        Naturforscher. Одна из самых первых попыток установить правила
        употребления кавычек в славянских языках (а следовательно, и в русском)
        была предпринята славянским учёным XV века Константином Философом в
        главе 17 его трактата «О письменехъ». Je n'ai qu'un jour pour visiter
        Paris. Je suis l'itinéraire donné par Pierre, un ami français. Je
        commence par un petit-déjeuner dans un café près de l'Arc de Triomphe
        avec des croissants délicieux. Après une marche sur les Champs-Élysées,
        je suis au pied de la Tour Eiffel. Il y a beaucoup d'attente pour les
        ascenseurs (pour monter les étages) mais je suis enfin au sommet et peux
        apprécier la vue sur Paris.
      </P>
      <H2>How many alphabets can we fit here</H2>
      <P>
        Line length = 2.31 alphabets
        <br />
        abcde­fghijklmnopqrstu­vwxyz­abcde­fghijklmnopqrstu­vwxyz­abcde­fgh
        <br />
      </P>
      <H2>Lists as a layout tool</H2>
      <P>What do we know about lists? Not quite much.</P>
      <List>
        <Li>
          <P>
            Obviously, manually typing bullets or numbers is not the best idea
            to make lists. In HTML it’s wise to use <Code>{'<ul>'}</Code> or{' '}
            <Code>{'<ol>'}</Code> elements instead.
          </P>
        </Li>
        <Li>
          <P>
            It’s usually a good idea to add some extra vertical space between
            list items, especially if the items span multiple lines. Putting the
            text in a properly styled <Code>{'<p>'}</Code> element does the job.
          </P>
        </Li>
        <Li>
          <P>
            Default bullets may appear way too dark, drawing the reader’s
            attention away from the text. Replacing them can help restore
            balance.
          </P>
          <CodeBlock>{listItemSnippet}</CodeBlock>
        </Li>
        <Li>
          <P>
            When used with generous indentation, lists help to maintain visual
            hierarchy of the document, which makes them a good alternative to
            deeply nested headings.
          </P>
        </Li>
      </List>
      <P>
        Although recipes won’t make us better at cooking, they might sharpen our
        design skills. Unlike French omlet, HTML is quite boring to me. So here
        we go.
      </P>
      <List ordered>
        <Li>
          <P>Beat eggs, water, salt and pepper in small bowl until blended.</P>
        </Li>
        <Li>
          <P>
            Heat butter in 6–8″ nonstick omelet pan or skillet over medium-high
            heat until hot. Tilt pan to coat bottom. Pour in egg mixture.
            Mixture should set immediately at edges.
          </P>
        </Li>
        <Li>
          <P>
            Gently push cooked portions from edges toward the center with
            inverted turner so that uncooked eggs can reach the hot pan surface.
            Continue cooking, tilting pan and gently moving cooked portions as
            needed.
          </P>
        </Li>
        <Li>
          <P>
            When top surface of eggs is thickened and no visible liquid egg
            remains, place filling on one side of the omelet. Fold omelet in
            half with turner. With a quick flip of the wrist, turn pan and
            invert or slide omelet onto plate. Serve immediately.
          </P>
        </Li>
      </List>
      <P>
        For beginners, ⅓ to ½ cup filling for a 2-egg omelet can be difficult to
        manage at first. For those who’re in trouble it’s recommended to put
        only half the filling inside the French omlet.
      </P>
      <H2>
        Figures 0123456789, <TabularLining>0123456789</TabularLining>
      </H2>
      <P>
        <TabularLining>
          $3,460 €8,895 £1,435
          <br />
          $7,031 €9,215 £8,991
        </TabularLining>
      </P>
      <H2>Laying out pictures</H2>
      <P>
        It’s not always necessary to fit a picture in the column of text, which
        unlike the picture is supposed to be{' '}
        <A href="#line-length">narrow enough</A>.
      </P>
    </Text>
    <Figure src={pic}>
      <FigCaption>
        <Text>
          Have I shared a few tips on distracting a reader away from the text?
          Well, I forgot to mention that it doesn’t have to be something on the
          screen…
        </Text>
      </FigCaption>
    </Figure>
    <Text>
      <H2>Server-side rendering</H2>
      <P>
        This website is merely a React app which loads pages asynchronously by
        splitting the underlying JavaScript code into chunks. Although it is
        possible to accomplish this using webpack alone, a problem arises when
        pre-rendering HTML pages server-side. That’s why there are libraries
        like <A href="#">loadable-components</A> which helps to avoid tons of
        loading indicators instead of actual content.
      </P>
      <CodeBlock>npm install --save-exact loadable-components</CodeBlock>
      <P>
        Here is an example of a <NoWrap>higher-order</NoWrap> component, which
        produces an asynchronously loadable, prefetchable component (i.e. page)
        given it’s module path:
      </P>
      <CodeBlock>{jsCodeExample}</CodeBlock>
      <P>
        The loadable-components library comes with webpack and babel plugins,
        and a module for extracing script tags and related markup. Together,
        they relieve pain of server-side pre-rendering.
      </P>
      <H2>Section with multiple paragraphs</H2>
      <P>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Morbi non arcu risus
        quis. Vestibulum mattis ullamcorper velit sed. Id nibh tortor id aliquet
        lectus proin nibh. Vulputate ut pharetra sit amet.
      </P>
      <P>
        Sit amet est placerat in. Arcu dui vivamus arcu felis bibendum. Nisl
        rhoncus mattis rhoncus urna neque viverra justo. Cursus metus aliquam
        eleifend mi in nulla posuere. Egestas erat imperdiet sed euismod.
        Volutpat est velit egestas dui id ornare arcu odio ut. Hac habitasse
        platea dictumst vestibulum rhoncus. Commodo nulla facilisi nullam
        vehicula ipsum. Metus vulputate eu scelerisque felis imperdiet proin
        fermentum leo. Massa id neque aliquam vestibulum morbi blandit. Faucibus
        scelerisque eleifend donec pretium.
      </P>
      <P>
        Nec ultrices dui sapien eget. Commodo ullamcorper a lacus vestibulum
        sed. Et sollicitudin ac orci phasellus egestas tellus rutrum tellus.
        Amet cursus sit amet dictum sit amet justo.
      </P>
    </Text>
  </ArticlePage>
);

export default DemoArticle;
